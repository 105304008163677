$text-main-color: #222222;
$text-dark: #141619;
$background-color: #f7f8fb;
$color-primary: #192038;
$color-secondary: #425466;
$color-white: #fff;
$color-red: #f21d1d;
$colo-red-light: #f66060;
$btn-primaryColor: #1da1f2;
$border-color: #ecedf1;
$color-dark: #141619;
$light-blue: #e8f6fe;
$color-blue-buttons: #1da0f2;
$cardTitleBackground: #edf1f7;
$grey-40: rgba(66, 84, 102, 0.4);
$reject: rgba(242, 29, 29, 0.1);
$dark-blue: #495057;
$status-succes: #66cb9f;
$btn-abort: #f5f5f5;
$btn-color-grey: #7f7f7f;
$badge-color: #56ccf2;
$description-grey: rgba(66, 84, 102, 0.4);
$light-blue: rgba(29, 161, 242, 0.1);
$light-grey: #3cedf1;
$grey-l2: #f4f7fa;
$grey-l3: #f2f2f2;
$grey-l4: #eee;
$grey-l5: #f9f9f9;
$grey-l6: #f7f7f7;
$grey-l7: #c1c1c1;
$grey-l8: rgba(237, 241, 247, 0.6);
$grey-l9: #ddd;
$dark-color-d2: #27272e;
$dark-color-d3: rgba(67, 83, 102, 0.6);
$dark-color-d4: #b3bbc2;
$dark-color-d5: #212529;
$panel-background-80-color: rgba(33, 37, 41, 0.8);
$success-color: #6fcf97;
$error-color: #eb5757;
$neutral-color: #5999f1;
$likes-color: #2f80ed;
$logout-color: rgba(189, 11, 11, 0.99);
$black-color: #000;
$default-link-color: rgb(29, 161, 242);
$breadcrumb-color: rgba(66, 84, 102, 0.4);
$trigger-bar: #231f20;
$mobile-spacer: rgba(0, 0, 0, 0.15);
$dark-blue-l3: #007bff;
$blue-l4: #2f80ed;
$danger-color-bg: #f8d7da;
$green-label-color: #5ca943;
$green-badge: #00c851;
$menu-background: #181c32;
$border-color-table: #ebeced;
$input-error: #dc3545;
$default-button-color: #455065;
$light-grey1: #cccccc;
$default-btn-hover: #f3f4f6;
$body-color: #f5f8fa;
$description-color: #adadad;
$label-color: #adadad;
$mainTitleColor: #323c47;
$btn-blue: #009ef7;
$btn-green: #50cd89;
$btn-light-blue: #f1faff;
$btn-grey: #4b4b4b;
