@import "colors";

.container {
  max-width: 1440px !important;
  padding: 0 32px;
  @media screen and (max-width: 1199px) {
    padding: 0 15px;
  }
}

.text-primary {
  color: $color-primary !important;
}
.text-secondary {
  color: $color-secondary;
}
.text-white {
  color: $color-white;
}
.text-error {
  color: $color-red;
}

.border-error {
  border: 1px solid $color-red !important;
}

.bg-white {
  background-color: $color-white;
}

.cursor-pointer {
  cursor: pointer;
}

// Override Card

.card {
  box-shadow: 0 0 1px rgba(12, 26, 75, 0.24),
    0 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 12px;
  border: 0;
  &-header,
  &-body,
  &-footer {
    background: transparent;
    padding: 24px;
    border-radius: 0 !important;
    border: 0;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &-title {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: $color-dark;

    &.has-border {
      border-bottom: 1px solid $border-color;
      padding-bottom: 10px;
    }
  }

  &-footer {
    text-align: center;
    a {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: $btn-primaryColor;
      transition: all 0.3s ease-in;
      &:hover {
        text-decoration: none;
      }
    }
  }

  &.hasCustomTitle {
    .card-title {
      border-top: 3px solid $color-white;
      border-left: 3px solid $color-white;
      border-right: 3px solid $color-white;
      border-radius: 10px 10px 0 0;
      background: $cardTitleBackground;
      padding: 17px 24px;
      margin: 0;

      .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.02em;
        color: $color-dark;
        margin: 0;
      }
    }

    @media screen and (max-width: 1199px) {
      .card-title {
        padding: 15px;

        .title {
          font-size: 15px;
        }
      }
    }
  }
}

// Buttons overrides

.btn {
  outline: 0 none;
  box-shadow: none;
  line-height: 17px;
  letter-spacing: 0.02em;
  padding: 12px 20px;
  transition: all 0.3s ease;
  font-size: 14px;
  min-width: 120px;
  &-primary {
    background: $btn-primaryColor;
    color: $color-white;
    text-align: center;
    border-color: $btn-primaryColor;
  }

  &-check {
    background-color: #50cd89;
    color: $color-white;
    text-align: center;
    border-color: #50cd89;
    &.active {
      background-color: #009ef7;
      border-color: #009ef7;
    }
    &:hover {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    }
  }

  &.btn-reject {
    background: $reject;
    font-weight: 500;
    border-radius: 4px;
    color: #f21d1d;
  }

  &.btn-abort {
    background-color: #f5f8fa;
    color: #7e8299;
  }

  &.btn-secondary {
    background: #f1faff;
    border-radius: 4px;
    color: #009ef7 !important;
    border-color: #f1faff;
    &:hover {
      opacity: 0.8;
    }
    &:focus,
    &:active {
      background: #f1faff !important;
      color: #009ef7 !important;
    }
  }

  &.btn-green {
    background-color: $btn-green;
    color: white;
  }

  &-transparent {
    background: $light-blue;
    color: $btn-primaryColor;
  }

  &.status-button {
    padding-top: 5px;
    padding-bottom: 5px;
    text-transform: uppercase;
    cursor: default !important;
    font-weight: bold;

    &-primary {
      color: $btn-primaryColor;
      border-color: $btn-primaryColor;
    }

    &-danger {
      color: $color-red;
      border-color: $color-red;
    }

    &-details {
      text-transform: none !important;
      margin-left: 10px;
    }
  }

  &.review-button {
    text-transform: uppercase;
    cursor: default !important;
    color: $color-red;
    border-color: $color-red;
    margin-right: 10px;
  }

  &-abort {
    background-color: #f5f5f5;
    color: #7f7f7f;
  }

  &.small {
    font-size: 13px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

// Page title Style

.page-title {
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  color: #222222;
  margin-bottom: 8px;
  margin-top: 0;
  @media screen and (max-width: 1199px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

// Category badge style

.category-badge {
  background: #edf1f7;
  border-radius: 18px;
  padding: 9px 12px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-transform: uppercase;
  color: $color-primary !important;
  transition: all 0.3s ease-in;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  width: auto;
  &:hover {
    text-decoration: none;
    background-color: $color-white;
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.24),
      0 3px 8px -1px rgba(50, 50, 71, 0.05);
  }
  &:not(:last-child) {
    margin-right: 6px;
    @media screen and (max-width: 768px) {
      margin-right: 12px;
    }
  }

  &:last-child {
    margin-right: 0;
  }
  .icon {
    max-height: 13px;
    max-width: 14px;
    margin-right: 8px;
  }
  &.social {
    background: $color-white;
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.24),
      0 3px 8px -1px rgba(50, 50, 71, 0.05);
    border-radius: 16px;
    @media screen and (min-width: 768px) {
      align-self: flex-start;
    }
  }
  &.voting {
    padding: 5px 14px 5px 8px;
    .icon {
      max-width: 24px;
      max-height: 24px;
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 1199px) {
    padding: 10px;
    margin-bottom: 5px;
  }
}

.thought__islandCounter {
  margin-left: auto;
  display: flex;
  align-items: center;
  align-self: center;
  &-icon {
    width: 32px;
    height: 32px;
  }
  &-value {
    font-weight: 500;
    font-size: 12px;
    margin-left: 4px;
    width: max-content;
    color: black;
  }
  &-separator {
    margin-left: 5px;
    margin-right: 1px;
  }
}

// Generic page Elements

.tw-title {
  font-size: 24px;
  line-height: 29px;
  font-weight: bold;
  margin-bottom: 24px;
  &.small {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 8px;
    letter-spacing: 0.02em;
  }
}

.tw-pageTop-space {
  margin-top: 45px;
  @media screen and (max-width: 1199px) {
    margin-top: 20px;
  }
}

.thought__details {
  &-date {
    color: rgba(66, 84, 102, 0.4);
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;

    span {
      margin: 0 5px;
    }
  }
}

.thought-description {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: $color-secondary;
  white-space: pre-line;
}

.tw__weight {
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  margin-bottom: 16px;
  &-icon {
    width: 32px;
    height: 32px;
    &.big {
      width: 36px;
      height: 36px;
    }
  }
  &-value {
    margin: 0 8px;
    line-height: 1;
  }
  @media screen and (max-width: 568px) {
    font-size: 12px;
  }
}

.tw_votes {
  margin-left: auto;
  margin-bottom: 16px;
  line-height: 1;
  display: flex;
  align-items: flex-end;
}

.pointer {
  cursor: pointer;
}

.spinner-grow-sm {
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-left: 4px;
}

.modal-header {
  button {
    &.close {
      outline: 0 none;
    }
  }
}

.custom-table-style {
  text-align: center;
  ::ng-deep {
    .ag-root-wrapper,
    .ag-header,
    .ag-row {
      border: 0;
    }
    .ag-root-wrapper {
      background-color: transparent;
    }
    .ag-row {
      background-color: $color-white;
      border-bottom: 10px solid $background-color;
    }
    .ag-cell {
      padding-top: 13px;
      outline: 0;
      border: 0 !important;
    }
    .profile {
      width: 40px;
      height: 40px;
    }
    .profile-name {
      margin-left: 8px;
      font-weight: bold;
      color: $color-dark;
      &:hover {
        text-decoration: none;
      }
    }
    .ag-cell-focus {
      border: 0 !important;
    }
    .ag-header-cell-text {
      font-weight: bold;
      font-size: 12px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: #425466;
    }
    .toggle-trust {
      background: rgba(29, 161, 242, 0.1);
      border-radius: 4px;
      color: $btn-primaryColor;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.02em;
      padding: 11px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.active {
        background-color: $color-white;
        border: 1px solid $btn-primaryColor;
      }
    }
    .ag-header-cell-resize {
      display: none;
    }
    .ag-pinned-left-header,
    .ag-horizontal-left-spacer {
      border: 0;
    }
    .ag-cell-label-container {
      align-items: center;
      .ag-header-cell-label {
        justify-content: center;
      }
    }
    .ag-floating-filter-full-body {
      justify-content: center;
    }
  }
}
