@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* You can add global styles to this file, and also import other style files */
$alert-padding-x: 0.25rem;
$alert-padding-y: 0.25rem;
$alert-margin-bottom: 0.25rem;
$alert-border-width: 0.25rem;

@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";

@import "~bootstrap/scss/bootstrap";
@import "app/styles/bootstrap-override";
@import "app/styles/colors";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: normal;
}

body,
html {
  font-family: "Poppins", sans-serif, Arial;
  overflow-x: hidden;
}

.alert-custom {
  font-size: small;
  margin-top: 2px;
  color: red;
}

.font-size-small {
  font-size: small;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #192038;
  width: 25px;
  height: 25px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.modal-right-wrapper {
  position: fixed;
  width: 600px;
  min-height: 100vh;
  height: 100%;
  top: 0;
  right: -100%;
  transition: all 0.4s linear;
  z-index: 4;
  background-color: #fff;
  box-shadow: -15px 0px 17px -7px rgba(0, 0, 0, 0.25);
  overflow-y: scroll;

  &.show {
    right: 0;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 20px 20px 90px;
    min-height: auto;
    overflow-y: scroll;
    top: 78px;
  }
}

.modal-background {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  cursor: pointer;
}
